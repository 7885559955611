<template>
  <div class="header">
    <div class="logo"><img src="../assets/logo.png">
      <span id="name">兴贸通</span>
      <span id="line"></span>
      <span id="title">您的一站式外贸管家</span>
    </div>
    <el-menu
        class="el-menu-header"
        :default-active="$route.path"
        mode="horizontal"
        background-color="transparent"
        text-color="#fff"
        active-text-color="#fff"
        router>
      <el-menu-item index="/">首页</el-menu-item>
      <el-submenu index="2">
        <template slot="title">平台服务</template>
        <el-menu-item index="/platformServices" class="custom-submenu-bg">外汇服务</el-menu-item>
        <el-menu-item index="/customs">通关服务</el-menu-item>
        <el-menu-item index="/taxRefund">退税服务</el-menu-item>
        <el-menu-item index="/logistics">物流服务</el-menu-item>
        <el-menu-item index="creditInsurance">信保服务</el-menu-item>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">金融服务</template>
        <el-menu-item index="/creditProtectFinancing">信保融资</el-menu-item>
      </el-submenu>
      <el-submenu index="4">
        <template slot="title">增值业务</template>
        <el-menu-item index="/schoolEnterprise">人才服务</el-menu-item>
        <el-menu-item index="/exhibition">会展服务</el-menu-item>
        <el-menu-item index="/assistOverseas">海外仓服务</el-menu-item>
        <el-menu-item index="/partyService">外贸第三方服务</el-menu-item>
      </el-submenu>
      <el-menu-item index="5">新闻咨询</el-menu-item>
      <el-submenu index="6">
        <template slot="title">关于我们</template>
        <el-menu-item index="/companyProfile">平台简介</el-menu-item>
        <el-menu-item index="/contactUs">联系我们</el-menu-item>
        <el-menu-item index="/joinUs">加入我们</el-menu-item>
      </el-submenu>
    </el-menu>
    <div class="header-right">
      <el-input v-model="search" placeholder="关键词搜索" size="mini" suffix-icon="el-icon-search"></el-input>
      <el-button type="text" class="button-left button-wide">注册</el-button>
      <el-button type="text" class="button-wide">登录</el-button>
      <el-button type="text" class="button-left">简体</el-button>
      <el-divider direction="vertical"></el-divider>
      <el-button type="text">ENG</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: ''
    }
  },

}
</script>

<style lang="less" scoped>
.header {
  background-image: linear-gradient(to right, rgba(98, 205, 196), rgba(33, 182, 234));
  display: flex;
  color: white;

  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }

  .el-menu-header {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    font-size: 20px;
    font-weight: 800;
  }
 /deep/.el-submenu__title:hover,.el-menu-item:hover {
    background-color: rgb(14, 183, 198) !important;
}


  .logo {
    min-height: 60px;
    min-width: 60px;
    padding: 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
    }

    #name {
      padding: 0 10px;
      font-weight: 800;
      font-size: 28px;
    }

    #line {
      border-right: 2px solid #ffffff;
      display: inline-block;
      height: 46px;
    }

    #title {
      padding: 0 10px;
      font-size: 20px;
    }
  }

  .header-right {
    display: flex;
    align-items: center;
    padding-right: 30px;

    /deep/ .el-input__inner {
      background-color: rgba(0, 0, 0, 0) !important;
      border: 1px solid #ffffff;
      border-radius: 50px;
      padding: 0 10px;
      color: white;
    }

    .el-button {
      color: white;
      font-size: 14px;
    }

    .button-left {
      padding-left: 30px;
    }

    .button-wide {
      font-weight: 800;
    }
  }    
}

</style>
<style lang="less">
  .el-menu--popup-bottom-start {
    background: rgba(98, 205, 196) !important;

  }
  .el-menu--horizontal .el-menu .el-menu-item {
    background: rgba(98, 205, 196) !important;
  }
  .el-menu--horizontal .el-menu .el-menu-item:hover {
    background: rgb(14, 183, 198) !important;
  }
</style>
