<template>
  <div id="advantageTitle">
    {{content}}
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: true,
      default: '小额投保全年免费'
    }
  },
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
#advantageTitle{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  width: 260px;
  height: 60px;
  background-image: linear-gradient(to right, rgba(98, 205, 196), rgba(33, 182, 234));
  color: white;
  margin: auto;
  font-size: 26px;
  font-weight: bold;
}
</style>


