<template>
  <div id="image">
    <el-carousel :interval="interval" arrow="hover" indicator-position="inside">
      <el-carousel-item v-for="(item, index) in items" :key="index">
        <img :src="item.image" alt="">
        <div class="h-title">{{item.title}}</div>
        <div class="config-title">{{item.content}}</div>
      </el-carousel-item>
    </el-carousel>
  </div>

</template>

<script>
export default {
  name: 'Carousel',
  data() {
    return {
      items: [
        {
          image: require('@/assets/homepageimges/qiaoliang.jpg'),
          title: '贸通全球 · 富民兴商',
          content: '助力企业数智化高效出海'
        },
        {
          image: require('@/assets/homepageimges/qiaoliang.jpg'),
          title: '贸通全球 · 富民兴商',
          content: '助力企业数智化高效出海'
        },
        {
          image: require('@/assets/homepageimges/qiaoliang.jpg'),
          title: '贸通全球 · 富民兴商',
          content: '助力企业数智化高效出海'
        }
      ],
      interval: 3000
    }
  }
}
</script>

<style lang="less" scoped>
#image {
  max-height: 700px;
  width: 100%;
  img {
    width: 100%;
    max-height: 700px;
  }
  /deep/.el-carousel__container {
    height: 700px;
  }
}
.h-title {
  position: absolute;
  top: 150px;
  left: 120px;
  color: white;
  font-size: 70px;
  font-weight: 700;
}
.config-title {
  position: absolute;
  top: 250px;
  color: white;
  left: 120px;
  font-size: 30px;
  font-weight: 400;
  padding: 2px 8px 3px 8px;
  text-align: center;
  cursor: pointer;
}
</style>
