<template>
  <div id="contactUs">
    <titleBar :image=this.image :title-cn="this.titleCn" :title-en=this.titleEn />
    <titleFont title="联系我们"></titleFont>
    <div class="content">
      <div v-for="(item, index) in items" :key="index" class="serves">
            <Service :image="item.image" :title="item.title" :content="item.content"/>
     </div>
    </div>
    <footLink/>
  </div>
</template>

<script>
import titleBar from "@/components/common/titleBar.vue";
import footLink from "@/components/common/footLink.vue";
import titleFont from "@/components/common/titleFont.vue";
import Service from '@/components/home/Service-wt';
export default {
  components: {
    titleBar,
    footLink,
    titleFont,
    Service
  },
  data() {
    return {
      titleCn: '联系我们',
      titleEn: 'CONTACT US',
      image: require('@/assets/contactUs/1.jpg'),
      items: [
        {
          image: require('@/assets/contactUs/11.png'),
          content: '西安市碑林区长安北路113号大话南门壹中心2413',
        },
        {
          image: require('@/assets/contactUs/12.png'),
          content: '029-85230860',
        },
        {
          image: require('@/assets/contactUs/13.png'),
          content: '821182998@qq.com',
        },
        {
          image: require('@/assets/icon/finance.png'),
        }
      ]
    }
  },
}
</script>

<style lang="less" scoped>
#contactUs{
  width: 100%;
  text-align: center;
  
}

.content {
  display: flex;
  align-items: center;
  margin-top: 50px;
  flex-wrap: wrap; /* 如果你希望它们能换行的话 */
  justify-content: center; /* 可选，用于水平居中对齐 */
  margin-bottom: 70px;
} 
.serves {
  display: flex;
  margin-top: 30px;
  padding-left: 7vh;
  margin: 10px; /* 根据需要调整间距 */
  height: 275px !important; 
}

.title {
  font-size: 30px;
  margin-bottom: 10px;
  font-weight: bold;
  background: -webkit-linear-gradient(to right,  rgba(98, 205, 196), rgba(33, 182, 234)); /* Chrome, Safari */
  background: linear-gradient(to right, rgba(98, 205, 196), rgba(33, 182, 234)); /* 标准语法 */
  -webkit-background-clip: text; /* Chrome, Safari */
  -webkit-text-fill-color: transparent; /* Chrome, Safari */
  color: transparent; /* 兼容不支持background-clip的浏览器 */
}
.content-font {
  color: black;            /* 文字颜色 */
  font-size: 20px;            /* 字号 */
  line-height: 2;           /* 行高 */
  text-align: left;           /* 对齐方式 */
  letter-spacing: 0.5px;      /* 字母间距 */
  word-spacing: 5px;          /* 单词间距 */
}

</style>


