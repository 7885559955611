<template>
  <div>
    <titleBar :image=this.image :title-cn="this.titleCn" :title-en=this.titleEn />
    <titleFont :title=this.titleCn></titleFont>
    <div id="content">
      <div id="content-title">
        <p>
          平台与各大高校部门合作组织人才培养，实习，业务培训。<br/>
          专注外贸垂直领域人才价值提升，将优秀外贸人才与优质外贸企业及相关行业机构进行有效匹配，同时提供<br/>
          一站式的人才培训孵化及人力综合服务。
        </p>
      </div>
      <div id="content-imgs">
        <img src="@/assets/schoolEnterprise/1.jpg"/>
        <img src="@/assets/schoolEnterprise/2.jpg"/>
        <img src="@/assets/schoolEnterprise/3.jpg"/>
      </div>
    </div>
    <footLink/>
  </div>
</template>

<script>
import titleBar from "@/components/common/titleBar.vue";
import footLink from "@/components/common/footLink.vue";
import titleFont from "@/components/common/titleFont.vue";

export default {
  components: {
    titleBar,
    footLink,
    titleFont
  },
  data() {
    return {
      titleCn: '人才服务',
      titleEn: 'SCHOOL-ENTERPRISE COOPERATION',
      image: require('@/assets/schoolEnterprise/4.jpg'),
    }
  },
}
</script>

<style lang="less" scoped>
#content {
  margin-top: 20px;
  margin-bottom: 50px;
}
.content {
  margin-top: 50px;
}
#content-title {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 2;           /* 行高 */
  text-align: center;           /* 对齐方式 */
  letter-spacing: 0.5px;      /* 字母间距 */
  word-spacing: 5px;          /* 单词间距 */
}
#content-imgs {
  display: flex;
  align-items: center;
  justify-content: center;
}
img {
  margin-right: 30px;
  width: 25%;
}
span {
  color: rgba(98, 205, 196);
}
</style>


