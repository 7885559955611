<template>
  <div>
    <titleBar :image=this.image :title-cn="this.titleCn" :title-en=this.titleEn />
    <div id="service">
      <div class="service service1">
        <div class="content">
          <el-row class="total"><span class="fk"></span>服务介绍</el-row>
          <el-row class="cont">为一般贸易出口客户，提供全流程退税服务：</el-row>
          <el-row class="cont">1.备案单证管理，退税资料准备</el-row>
          <el-row class="cont">2.退税函调跟踪服务</el-row>
        </div>
        <div>
          <img class="img-right" src="@/assets/taxRefund/2.jpg">
        </div>
      </div>
      <div class="service service2">
        <div>
          <img class="img-left" src="@/assets/taxRefund/3.jpg">
        </div>
        <div class="content">
          <el-row class="total"><span class="fk"></span>退税条件</el-row>
          <el-row class="cont">1.通过兴贸通报关出口</el-row>
          <el-row class="cont">2.已收齐全额发票</el-row>
          <el-row class="cont">3.生产工厂开具增值税专用发票且已通过兴贸通认证。</el-row>
        </div>
      </div>
      <div class="service service2">
        <div class="content">
          <el-row class="total"><span class="fk"></span>我们的优势</el-row>
          <el-row class="cont">1.提供垫付退税服务，最快1天就能实现退税到账。</el-row>
          <el-row class="cont">2.专业人员办理，加速企业资金周转。</el-row>
        </div>
        <div>
          <img class="img-right" src="@/assets/taxRefund/4.jpg">
        </div>
      </div>
    </div>
    <process :arrows=this.arrows />
    <footLink/>
  </div>
</template>

<script>
import titleBar from "@/components/common/titleBar.vue";
import footLink from "@/components/common/footLink.vue";
import process from "@/components/common/process.vue";

export default {
  components: {
    process,
    titleBar,
    footLink
  },
  data() {
    return {
      titleCn: '退税服务',
      titleEn: 'TAX REFUND SERVICE',
      image: require('@/assets/taxRefund/1.jpg'),
      arrows: [
        {
          serial:'01',
          describe:'注册'
        },
        {
          serial:'02',
          describe:'下单'
        },
        {
          serial:'03',
          describe:'报关'
        },
        {
          serial:'04',
          describe:'收付款'
        },
        {
          serial:'05',
          describe:'出口退税'
        },
        {
          serial:'06',
          describe:'结算'
        }
      ]
    }
  },
}
</script>

<style lang="less" scoped>
#service{
  width: 100%;
  margin: auto;
  display: grid;
  place-items: center;
}
.service {
  width: 1200px;
  height: 220px;
  border-radius: 15px; /* 设置圆角大小 */
  display: flex;
  background-color: gainsboro;
}
.service1 {
  margin-bottom: 40px;
  margin-top: 40px;
}

.service2 {
  margin-bottom: 40px;
}
img {
  height: 220px;
  width: 600px;
}
.img-left{
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.img-right{
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.content {
  width: 600px;
}

.total {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: 900;
  background-image: linear-gradient(to right, rgb(68, 221, 208), rgb(9, 182, 245));
  -webkit-background-clip: text; /* 使背景渐变应用于文本 */
  -webkit-text-fill-color: transparent; /* 使文本颜色透明，从而显示背景渐变 */
  display: flex;
  margin-left: 30px;
  margin-top: 30px;
  align-items: center; /* 垂直居中对齐 */
}

.cont {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  margin-left: 30px;
  margin-top: 10px;
}

.fk {
  display: inline-block;
  width: 22px; /* 设置方块的宽度 */
  height: 22px; /* 设置方块的高度 */
  background: linear-gradient(to right, rgba(98, 205, 196), rgba(33, 182, 234));
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%); /* 右上角向左移动形成钝角 */
  line-height: 22px; /* 确保文本垂直居中 */
  margin-right: 10px; /* 设置与.total之间的间隔 */
}
</style>


