<template>
  <div>
    <titleBar :image=this.image   />
    <div id="content-container">
    <div id="content">
      <span class="titleContent-eng">COMPANY PROFILE</span>
      <span class="titleContent-cn">公司简介</span>
      <span class="contentDesc">
        兴贸通是一家基于互联网技术，利用供应链管理和数据对接等形式自主研发的一套外综服管理平台。
      </span>
      <br />
      <span class="contentDesc-bottom">  
        兴贸通作为海关通关、银行、中信保、税务等数据直联平台，通过先进的技术和不断创新的理念积极为企业提供通关、退税、外汇、物流、信保、金融等一站式流程化服务，专注为中小企业发展进出口贸易提供专业化服务与支持。
      </span>
      <span class="contentDesc-end">  
        贸通全球 富民兴商
      </span>
      </div>
      <img src="@/assets/companyProfile/2.jpg" alt="Additional Image" class="additional-image" />
    </div>
    <footLink/>
  </div>
</template>

<script>
import titleBar from "@/components/common/titleBar.vue";
import footLink from "@/components/common/footLink.vue";

export default {
  components: {
    titleBar,
    footLink
  },
  data() {
    return {
      titleCn: '公司简介',
      titleEn: 'COMPANY PROFILE',
      image: require('@/assets/companyProfile/1.jpg'),
    }
  },
}
</script>

<style lang="less" scoped>
#content-container {
  display: flex;
  align-items: flex-start; /* 调整对齐方式 */
  width: 80%;
}

#content {
  flex: 1; /* 让 content 占据剩余的可用空间 */
  height: 600px;
  width: 70%;
  margin-left: 6%;
}

.additional-image {
  width: auto; /* 调整图片的宽度 */
  height: 500px; /* 保持图片的纵横比 */
  margin-left: 20px; /* 图片与内容之间的间距 */
  margin-right: 100px;
  margin-top: 50px;
  margin-bottom: 50px;
}


.titleContent-cn {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-weight: 900;
  display: flex;
  margin-left: 150px;
  margin-top: 10px;
}

.titleContent-eng {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-weight: 900;
  display: flex;
  margin-left: 150px;
  margin-top: 50px;
}
.contentDesc {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 100;
  margin-left: 150px !important;
  margin-top: 50px !important;
  padding-left: 20px;
  display: block; /* 使 text-indent 生效 */
  text-indent: 2em; /* 缩进量 */
  margin: 0; /* 确保没有额外的外边距 */
  width: 60%;
}

.contentDesc-bottom {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 100;
  margin-left: 150px !important;
  margin-top: 20px;
  padding-left: 20px;
  display: block; /* 使 text-indent 生效 */
  text-indent: 2em; /* 缩进量 */
  margin: 0; /* 确保没有额外的外边距 */
  width: 60%;
}

.contentDesc-end {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 48px;
  font-weight: 900;
  background-image: linear-gradient(to right, rgba(98,205,196), rgba(33,182,234));
  -webkit-background-clip: text; /* 使背景渐变应用于文本 */
  -webkit-text-fill-color: transparent; /* 使文本颜色透明，从而显示背景渐变 */
  display: flex;
  margin-left: 170px;
  margin-top: 120px;
  width: 60%;
}

</style>


