<template>
  <div>
    <Carousel></Carousel>
    <div id="OurServices">
      <OurServices></OurServices>
    </div>
    <div id="advantage">
      <Advantage></Advantage>
    </div>
    <div id="assist">
      助力企业数智化高效出海
    </div>
    <div id="portable">
      便捷/专业/安全
    </div>
    <div id="platform">
      <img id="ship2" src="@/assets/homepageimges/platform.png">
    </div>
    <queryTool/>
    <homeFoot/>
  </div>
</template>

<script>
import Carousel from '../../components/home/Carousel.vue'
import OurServices from '../../components/home/OurServices.vue'
import Advantage from '../../components/home/Advantage.vue'
import queryTool from '../../components/home/queryTool.vue'
import homeFoot from '../../components/home/homeFoot.vue'

export default {
  components: {
    Carousel,
    OurServices,
    Advantage,
    queryTool,
    homeFoot
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="less" scoped>
#OurServices {
  margin-bottom: 100px;
}
#advantage {
  margin-bottom: 100px;
}
#assist {
  font-size: 40px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  text-align: center; /* 水平居中文本 */
  margin-bottom: 10px;
}
#portable {
  font-size: 26px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  text-align: center; /* 水平居中文本 */
  margin-bottom: 50px;
}
#platform {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  text-align: center; /* 水平居中文本 */
  margin-bottom: 50px;
  img {
    width: 30%;
  }
}

</style>


