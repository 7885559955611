<template>
  <div>
    <div class="footer">
      <el-row>友情链接:
        <el-link :underline="false" href="https://www.mofcom.gov.cn/" target="_blank">中华人民共和国商务部</el-link>
        <el-divider direction="vertical"></el-divider>
        <el-link :underline="false" href="http://www.customs.gov.cn/" target="_blank">海关总署</el-link>
        <el-divider direction="vertical"></el-divider>
        <el-link :underline="false" href="https://www.safe.gov.cn/" target="_blank">外汇管理局</el-link>
        <el-divider direction="vertical"></el-divider>
        <el-link :underline="false" href="https://www.samr.gov.cn/" target="_blank">国家质量监督检验检疫总局</el-link>
        <el-divider direction="vertical"></el-divider>
        <el-link :underline="false" href="https://www.chinatax.gov.cn/" target="_blank">国家税务总局</el-link>
      </el-row>
      <br>
      <el-row>© 兴贸通外贸及跨境电商综合服务平台</el-row>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.footer {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: #ffffff;
  text-align: center;
  opacity: 0.8;
  flex-wrap: wrap;
  .el-link {
    color: #ffffff;
  }
  .el-row {
    width: 100%;
  }
}
</style>
