<template>
  <div id="title" :style="{backgroundImage:`url(${this.image})`}">
    <div>
      <div class="title">{{this.titleEn}}</div>
      <div class="title">{{this.titleCn}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type:String,
      default:''
    },
    titleEn: {
      type:String,
      default:''
    },
    titleCn: {
      type:String,
      default:''
    }
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="less" scoped>
#title {
  height: 250px;
  color: white;
  font-size: 28px;
  font-weight: bold;
  display: flex;
  align-items: center;
  background-size: 100% 100%;
  margin-top: 60px;
}
.title{
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 10px;
}
</style>
