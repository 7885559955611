<template>
  <div>
    <titleBar :image=this.image :title-cn="this.titleCn" :title-en=this.titleEn />
    <titleFont :title=this.titleCn></titleFont>
    <div id="content">
      <div id="content-title">
        <p>海外仓服务主要是为跨境电商卖家提供一系列服务，帮助他们更好地利用海外仓储设施来优化其国际物流和供应链管理。<br/>
          <span>优势:</span>出仓派送速度快，提升用户体验。节省物流成本。稳定库存，避免物流排仓、爆仓及补货压力。<br/>
          <span>海外仓类型卖家自建海外仓:</span>由跨境电商卖家自行投资建立的海外仓库。<br/>
          <span>第三方海外仓:</span>由专门的物流公司提供的海外仓储服务。<br/>
          <span>平台仓:</span>如亚马逊的Fulfillment by<br/>
          Amazon(FBA)服商平台提供的仓储和配送服务<br/></p>
      </div>
      <div id="content-imgs">
        <img class="img-item" src="@/assets/assistOverseas/1.jpg"/>
        <img class="img-item" src="@/assets/assistOverseas/2.jpg"/>
        <img class="img-item" src="@/assets/assistOverseas/3.jpg"/>
      </div>
      <div id="endImg">
        <img src="@/assets/assistOverseas/5.png"/>
      </div>
    </div>
    <footLink/>
  </div>
</template>

<script>
import titleBar from "@/components/common/titleBar.vue";
import footLink from "@/components/common/footLink.vue";
import titleFont from "@/components/common/titleFont.vue";

export default {
  components: {
    titleBar,
    footLink,
    titleFont
  },
  data() {
    return {
      titleCn: '海外仓服务',
      titleEn: 'ASSIST OVERSEAS WAREHOUSE',
      image: require('@/assets/assistOverseas/6.jpg'),
    }
  },
}
</script>

<style lang="less" scoped>
#content {
  margin-top: 20px;
  margin-bottom: 50px;
}
.content {
  margin-top: 50px;
}
#content-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 2;           /* 行高 */
  text-align: center;           /* 对齐方式 */
  letter-spacing: 0.5px;      /* 字母间距 */
  word-spacing: 5px;          /* 单词间距 */
}
#content-imgs {
  display: flex;
  align-items: center;
  justify-content: center;
}
img-item {
  margin-right: 30px;
  width: 25%;
}
#endImg {
  display: flex;
  align-items: center;
  justify-content: center;
}
span {
  color: rgba(98, 205, 196);
}
</style>


