<template>
  <div id="all">
    <div>
      <div class="circle">{{ this.serial }}</div>
      <div class="arrow">{{ this.describe }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    serial: {
      type: String,
      required: true,
      default: '01'
    },
    describe: {
      type: String,
      required: true,
      default: '报名申请'
    }
  },
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
#all {
  height: 200px;
}
.arrow {
  font-size: 18px;
  font-weight: bold;
  color: rgba(98, 205, 196);
  width: 200px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  background: white;
  margin: 1px;
  transition: border-color 0.3s; /* 边框颜色变化过渡时间 */
  border-radius: 5px; /* 圆角边框 */
  clip-path: polygon(0px 0px,
  calc(100% - 20px) 0px,
  100% 50%,
  calc(100% - 20px) 100%,
  0px 100%,
  20px 50%);
}

.circle {
  margin-left: 65px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  text-align: center; /* 水平居中文本 */
  font-size: 40px;
  color: white;
  width: 60px; /* 设置宽度 */
  height: 60px; /* 设置高度与宽度相等以形成圆形 */
  border: 2px solid white; /* 设置边框宽度和样式 */
  border-radius: 50%; /* 设置边框半径为50%，形成圆形 */
}
</style>


