import { render, staticRenderFns } from "./assistOverseas.vue?vue&type=template&id=5cbccc46&scoped=true"
import script from "./assistOverseas.vue?vue&type=script&lang=js"
export * from "./assistOverseas.vue?vue&type=script&lang=js"
import style0 from "./assistOverseas.vue?vue&type=style&index=0&id=5cbccc46&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cbccc46",
  null
  
)

export default component.exports