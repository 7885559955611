<template>
  <div id="advantage">
    <img id="ship" src="@/assets/homepageimges/ship.jpg">
    <div class="content">
      <br/><br/><br/>
      <sapn class="ptys">平台优势</sapn>
      <span class="pt-content">
        良好的服务始终是我们的追求!我们在市场的<br/>每一步都离不开您的支持!
      </span>
      <span class="more-content">
        <el-link :underline="false" @click="navigateTo('/platformAdvantage')">查看更多</el-link>
      </span>
    </div>
    <img id="ship2" src="@/assets/homepageimges/3.jpg">
  </div>
</template>

<script>
export default {
  name: 'Advantage.vue',
  data() {
    return {
      
    }
  },
  methods: {
    navigateTo(path) {
      this.$router.push(path);
    }
  }
}
</script>

<style lang="less" scoped>
#advantage{
  display: flex;
}
img{
  height: 400px;
  margin: auto;
}
#ship{
  margin-right: 0;
}
#ship2{
  margin-left: 0;
}
.content {
  background-color: aliceblue;
}
.ptys {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-weight: 900;
  background-image: linear-gradient(to right, rgba(98,205,196), rgba(33,182,234));
  -webkit-background-clip: text; /* 使背景渐变应用于文本 */
  -webkit-text-fill-color: transparent; /* 使文本颜色透明，从而显示背景渐变 */
  display: flex;
  margin-left: 30px;
}

.pt-content {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  margin-top: 30px ;
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
}

.more-content {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 900;
  background-image: linear-gradient(to right, rgba(98,205,196), rgba(33,182,234));
  -webkit-background-clip: text; /* 使背景渐变应用于文本 */
  display: inline-block; /* 使元素根据内容宽度调整大小 */
  margin-left: 30px;
  margin-top: 80px;
  padding: 10px 10px; /* 添加内边距以使边框显示 */
  border: 3px solid transparent; /* 设置透明边框 */
  border-radius: 12px; /* 设置圆角 */
  border-image: linear-gradient(to right, rgba(98,205,196), rgba(33,182,234)) 1; /* 设置渐变色边框 */
}
.el-link{
  font-weight: bold;
  font-size: 16px;
}
.el-link.el-link--default {
  color: rgba(98,205,196);
}
</style>
