<template>
  <div>
    <titleBar :image=this.image :title-cn="this.titleCn" :title-en=this.titleEn />
    <div id="content">
      <img :src="this.contentImage"/>
    </div>
    <footLink/>
  </div>
</template>

<script>
import titleBar from "@/components/common/titleBar.vue";
import footLink from "@/components/common/footLink.vue";

export default {
  components: {
    titleBar,
    footLink
  },
  data() {
    return {
      titleCn: '平台优势',
      titleEn: 'PLATFORM ADVANTAGE',
      image: require('@/assets/platformAdvantage/1.jpg'),
      contentImage: require('@/assets/platformAdvantage/2.png'),
    }
  },
}
</script>

<style lang="less" scoped>
#content {
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}
img {
  height: 80%;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>


