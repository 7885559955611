<template>
  <div>
    <titleBar :image=this.image :title-cn="this.titleCn" :title-en=this.titleEn />
    <titleFont :title=this.titleCn></titleFont>
    <div id="content">
      <div id="content-title">
        <p>
          <span>1.</span>展览策划与设计:根据客户的需求和目标，制定展览策划方案。展台设计、布置和装饰，以吸引参展商和观众的注意。<br>
          <span>2.</span>参展服务:展位预订、展品运输、展示器材租赁。展品搭建和拆除，确保参展商能顺利参展。<br>
          <span>3.</span>宣传与推广:通过市场调研和策划吸引目标观众群体参观展会。提供优质的观展体验，并进行相关的宣传推广活动，以<br>
          增加展会的知名度和影力。<br>
          <span>4.</span>会议组织与策划:协助客户组织各类会议活动，如学术研讨会、行业论坛、产品发布会等。在会议场地选择、议程安<br>
          排 、嘉宾邀请、现场管理等环节提供专业支持。<br>
          <span>5.</span>商务拓展与合作洽谈:为客户提供商务对接和合作治谈的平台。促进参展商之间及与观众之间的交流与合作，帮助他们<br>
          扩大业务网络和达成合作意向。<br>
        </p>
      </div>
      <div id="content-imgs">
        <img src="@/assets/exhibition/1.jpg"/>
        <img src="@/assets/exhibition/2.jpg"/>
        <img src="@/assets/exhibition/3.jpg"/>
      </div>
    </div>
    <footLink/>
  </div>
</template>

<script>
import titleBar from "@/components/common/titleBar.vue";
import footLink from "@/components/common/footLink.vue";
import titleFont from "@/components/common/titleFont.vue";

export default {
  components: {
    titleBar,
    footLink,
    titleFont
  },
  data() {
    return {
      titleCn: '会展服务',
      titleEn: 'EXHIBITION SERVICE',
      image: require('@/assets/exhibition/4.jpg'),
    }
  },
}
</script>

<style lang="less" scoped>
#content {
  margin-top: 20px;
  margin-bottom: 50px;
}
.content {
  margin-top: 50px;
}
#content-title {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 2;           /* 行高 */
  text-align: left;           /* 对齐方式 */
  letter-spacing: 0.5px;      /* 字母间距 */
  word-spacing: 5px;          /* 单词间距 */
}
#content-imgs {
  display: flex;
  align-items: center;
  justify-content: center;
}
img {
  margin-right: 30px;
  width: 25%;
}
span {
  color: rgba(98, 205, 196);
}
</style>


