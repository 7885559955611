import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/HomeView.vue'
import Layout from '../views/layout/Layout.vue'
import Login from '../views/login/Login.vue'
import platformServices from '../views/platformServices/platformServices.vue'
import companyProfile from '../views/companyProfile/companyProfile.vue'
import platformAdvantage from '../views/platformAdvantage/platformAdvantage.vue'
import joinUs from '../views/joinUs/joinUs.vue'
import contactUs from '../views/contactUs/contactUs.vue'
import partyService from '../views/partyService/partyService.vue'
import assistOverseas from '../views/assistOverseas/assistOverseas.vue'
import exhibition from '../views/exhibition/exhibition.vue'
import schoolEnterprise from '../views/schoolEnterprise/schoolEnterprise.vue'
import creditProtectFinancing from '../views/creditProtectFinancing/creditProtectFinancing.vue'
import customs from '../views/customs/customs.vue'
import taxRefund from '../views/taxRefund/taxRefund'
import logistics from '../views/logistics/logistics.vue'
import creditInsurance from '../views/creditInsurance/creditInsurance.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'home',
        component: HomeView
      },
      {
        path: '/platformServices',
        name: 'platformServices',
        component: platformServices
      },
      {
        path: '/companyProfile',
        name: 'companyProfile',
        component: companyProfile
      },
      {
        path: '/platformAdvantage',
        name: 'platformAdvantage',
        component: platformAdvantage
      },
      {
        path: '/joinUs',
        name: 'joinUs',
        component: joinUs
      },
      {
        path: '/contactUs',
        name: 'contactUs',
        component: contactUs
      },
      {
        path: '/partyService',
        name: 'partyService',
        component: partyService
      },
      {
        path: '/assistOverseas',
        name: 'assistOverseas',
        component: assistOverseas
      },
      {
        path: '/exhibition',
        name: 'exhibition',
        component: exhibition
      },
      {
        path: '/schoolEnterprise',
        name: 'schoolEnterprise',
        component: schoolEnterprise
      },
      {
        path: '/creditProtectFinancing',
        name: 'creditProtectFinancing',
        component: creditProtectFinancing
      },
      {
        path: '/customs',
        name: 'customs',
        component: customs
      },
      {
        path: '/taxRefund',
        name: 'taxRefund',
        component: taxRefund
      },
      {
        path: '/logistics',
        name: 'logistics',
        component: logistics
      },
      {
        path: '/creditInsurance',
        name: 'creditInsurance',
        component: creditInsurance
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router
