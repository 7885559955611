<template>
  <div>
    <!-- 导航 -->
    <Header :class="navBarFixed === true ? 'navBarWrap' :''"></Header>

    <router-view></router-view>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      navBarFixed: false,
    }
  },
  mounted () {
    window.addEventListener('scroll', this.watchScroll,true)
  },
  methods: {
    watchScroll () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.navBarFixed = scrollTop > 0;
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  position:fixed;
  top:0;
  z-index:9;
}
.footer {
  position: absolute;
  bottom: 0;
  height: 60px;
  width: 100%;
  color: white;
  text-align: center;
}
</style>


