<template>
  <div id="all">
    <div>
      <div class="arrow">{{ this.serial }}</div>
      <div class="divider"></div>
      <div class="dot-container">
        <div class="dot"></div>
      </div>
      <div class="describe">{{ this.describe }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    serial: {
      type: String,
      required: true,
      default: '01'
    },
    describe: {
      type: String,
      required: true,
      default: '注册'
    }
  },
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
#all {
  height: 150px;
}
.arrow {
  font-size: 40px;
  color: white;
  width: 200px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  background: linear-gradient(to right, rgba(98, 205, 196), rgba(33, 182, 234));
  clip-path: polygon(0px 0px,
  calc(100% - 20px) 0px,
  100% 50%,
  calc(100% - 20px) 100%,
  0px 100%,
  20px 50%);
}

.divider {
  border-left: 2px dashed rgba(33, 182, 234); /* 虚线颜色和宽度 */
  height: 60px; /* 线的高度 */
  margin: 0 100px; /* 线的两侧间距 */
}

.dot-container {
  position: relative;
}

.dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(33, 182, 234); /* 小圆点颜色 */
  width: 10px;
  height: 10px;
  border-radius: 50%; /* 圆形 */
}

.describe {
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}
</style>


