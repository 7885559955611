<template>
  <div id="service">
    <div class="h-image">
      <img :src="image">
    </div>
    <div class="h-title">{{title}}</div>
    <div class="config-title">{{content}}</div>
  </div>
</template>

<script>
export default {
  name: 'Carousel-wt',
  props: {
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
img {
  width: 100px;
  height: 100px;
}
#service {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 270px;
  height: 275px;
  border-radius: 10%;
  background-image: linear-gradient(to right, rgba(98,205,196), rgba(33,182,234));
}
.h-image {
  top: 15%;
  position: absolute;
}
.h-title {
  position: absolute;
  font-size: 30px;
  top: 50%;
}
.config-title {
  position: absolute;
  font-size: 15px;
  top: 60%;
  margin: 15px;
}
</style>
