<template>
  <div>
    <titleBar :image=this.image :title-cn="this.titleCn" :title-en=this.titleEn />
    <div id="service">
      <div class="service service1">
        <div>
          <img class="img-left" src="@/assets/creditProtectFinancing/1.jpg">
        </div>
        <div class="content">
          <el-row class="total"><span class="fk"></span>融资条件</el-row>
          <el-row class="cont">1.出口企业为一般纳税人(或小规模纳税人),且成立时间在一年以上,纳税评级B级以上;</el-row>
          <el-row class="cont">2.通过兴贸通外综服平台出口;</el-row>
          <el-row class="cont">3.国外买家在中信保有足够信用限额。</el-row>
        </div>
      </div>
      <div class="service service2">
        <div class="content">
          <el-row class="total"><span class="fk"></span>融资优势</el-row>
          <el-row class="cont">1.掌握海外买家真实经营状况，降低交易风险;</el-row>
          <el-row class="cont">2.规避贸易风险，保障收汇安全;</el-row>
          <el-row class="cont">3.按需按票投保，投保费率远低于市场价;</el-row>
          <el-row class="cont">4.循环额度，按保单放款，帮助客户及时处理账期缺口。</el-row>
        </div>
        <div>
          <img class="img-right" src="@/assets/platformServices/3.jpg">
        </div>
      </div>
    </div>
    <footLink/>
  </div>
</template>

<script>
import titleBar from "@/components/common/titleBar.vue";
import footLink from "@/components/common/footLink.vue";

export default {
  components: {
    titleBar,
    footLink
  },
  data() {
    return {
      titleCn: '信保融资',
      titleEn: 'CREDIT INSURANCE FINANCING',
      image: require('@/assets/platformServices/1.jpg'),
    }
  },
}
</script>

<style lang="less" scoped>
#service{
  width: 100%;
  margin: auto;
  display: grid;
  place-items: center;
}
.service {
  width: 1200px;
  height: 220px;
  border-radius: 15px; /* 设置圆角大小 */
  display: flex;
  background-color: gainsboro;
}
.service1 {
  margin-bottom: 40px;
  margin-top: 40px;
}

.service2 {
  margin-bottom: 40px;
}
img {
  height: 220px;
  width: 600px;
}
.img-left{
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.img-right{
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.content {
  width: 600px;
}
.process {
  height: 500px;
  background-image: url('/src/assets/platformServices/4.jpg');
}
.total {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: 900;
  background-image: linear-gradient(to right, rgb(68, 221, 208), rgb(9, 182, 245));
  -webkit-background-clip: text; /* 使背景渐变应用于文本 */
  -webkit-text-fill-color: transparent; /* 使文本颜色透明，从而显示背景渐变 */
  display: flex;
  margin-left: 30px;
  margin-top: 30px;
  align-items: center; /* 垂直居中对齐 */
}

.cont {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  margin-left: 30px;
  margin-top: 10px;
}

.fk {
  display: inline-block;
  width: 22px; /* 设置方块的宽度 */
  height: 22px; /* 设置方块的高度 */
  background: linear-gradient(to right, rgba(98, 205, 196), rgba(33, 182, 234));
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%); /* 右上角向左移动形成钝角 */
  line-height: 22px; /* 确保文本垂直居中 */
   margin-right: 10px; /* 设置与.total之间的间隔 */
}
</style>


