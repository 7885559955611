<template>
  <div id="link">
    <table>
      <thead>
      <tr class="tr">
        <th>平台服务</th>
        <th>金融服务</th>
        <th>增值服务</th>
        <th id="last-th">关于我们</th>
      </tr>
      </thead>
      <tbody>
      <tr class="tr">
        <td><el-link :underline="false" @click="navigateTo('/platformServices')">外汇服务</el-link></td>
        <td><el-link :underline="false" @click="navigateTo('/creditProtectFinancing')">信保融资</el-link></td>
        <td><el-link :underline="false" @click="navigateTo('/schoolEnterprise')">人才服务</el-link></td>
        <td><el-link :underline="false" @click="navigateTo('/companyProfile')">平台简介</el-link></td>
      </tr>
      <tr class="tr">
        <td><el-link :underline="false" @click="navigateTo('/customs')">通关服务</el-link></td>
        <td></td>
        <td><el-link :underline="false" @click="navigateTo('/exhibition')">会展服务</el-link></td>
        <td><el-link :underline="false" @click="navigateTo('/contactUs')">联系我们</el-link></td>
      </tr>
      <tr class="tr">
        <td><el-link :underline="false" @click="navigateTo('/taxRefund')">退税服务</el-link></td>
        <td></td>
        <td><el-link :underline="false" @click="navigateTo('/assistOverseas')">海外仓服务</el-link></td>
        <td><el-link :underline="false" @click="navigateTo('/joinUs')">加入我们</el-link></td>
      </tr>
      <tr class="tr">
        <td><el-link :underline="false" @click="navigateTo('/logistics')">物流服务</el-link></td>
        <td></td>
        <td><el-link :underline="false" @click="navigateTo('/partyService')">外贸第三方服务</el-link></td>
        <td></td>
      </tr>
      <tr class="tr">
        <td><el-link :underline="false" @click="navigateTo('/creditInsurance')">信保服务</el-link></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: []
    }
  },
  methods: {
    navigateTo(path) {
        this.$router.push(path);
        window.scrollTo(0, 0);
    }
  }
}
</script>

<style lang="less" scoped>
#link {
  height: 260px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, rgba(98, 205, 196), rgba(33, 182, 234));
  font-size: 18px;
}

table {
  width: 60%;
  margin: auto;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0;
  border: none;
}

.tr {
  height: 30px;
}

table, th, td {
  padding: 7px;
  border-right: 1px solid white; /* 表格单元格右边线 */
}

table, td:last-child {
  border-right: none; /* 最右侧单元格不显示右边线 */
}

#last-th {
  border-right: none;
}
.el-link{
  color: white;
  font-size: 16px;
}
.el-link.el-link--default {
  color: white;
}
</style>
