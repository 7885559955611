<template>
  <div id="titleFont">
    <div id="font">{{ this.title }}</div>
    <div class="box">
      <div class="line1">
      </div>
      <div class="line2"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
#font {
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  background: linear-gradient(to right, rgba(98, 205, 196), rgba(33, 182, 234));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  margin-bottom: 12px;
  margin-top: 20px;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  text-align: center; /* 水平居中文本 */
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.line1 {
  width: 80%;
  position: absolute;
  border-bottom: 2px solid;
  border-image: linear-gradient(to right, rgba(98, 205, 196), rgba(33, 182, 234)) 1;
  z-index: 1;
}

.line2 {
  width: 20%;
  border-bottom: 8px solid;
  border-image: linear-gradient(to right, rgba(98, 205, 196), rgba(33, 182, 234)) 1;
  position: absolute;
  z-index: 2;
}
</style>
