import { render, staticRenderFns } from "./homeFoot.vue?vue&type=template&id=6eaf7728&scoped=true"
import script from "./homeFoot.vue?vue&type=script&lang=js"
export * from "./homeFoot.vue?vue&type=script&lang=js"
import style0 from "./homeFoot.vue?vue&type=style&index=0&id=6eaf7728&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eaf7728",
  null
  
)

export default component.exports