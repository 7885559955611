<template>
  <div>
    <titleBar :image=this.image :title-cn="this.titleCn" :title-en=this.titleEn />
    <titleFont :title="this.titleCn"></titleFont>
    <div id="content">
      <div id="content-title">
        中国出口信用保险公司是由国家出资设立、支持中国对外经济贸易发展与合作、具有独立法人地位的国有政策性保险公司，于<br/>
        2001年12月18日正式挂牌运营，服务网络覆盖全国。兴贸通平台在中国信用保险公司陕西分公司的大力支持下，将以更简便、更高<br/>
        效、更优惠费率的信保政策为中小微企业提供全面优质的服务。分担出口风险，保障收汇安全!兴贸通信保方案为您量身打造适合<br/>
        您的投保套餐，欢迎随时咨询。
      </div>
      <div class="title1">兴贸通投保四大优势</div>
      <div id="content-advantage">
        <div v-for="(item, index) in advantageTitle" :key="index" class="advantageTitle">
          <advantageTitle :content="item"/>
        </div>
      </div>
      <div class="title1">信保功能</div>
      <div>
        <div class="function-content">
          <div>1.规避贸易风险 保障收汇安全</div>
          <div>3.转变经营理念 提高风控能力</div>
        </div>
        <div class="function-content">
          <div>2.掌握有限资源 提高竞争能力</div>
          <div>4.利用保单融资 加速资金周转</div>
        </div>
      </div>
      <div id="content-imgs">
        <img src="@/assets/creditInsurance/2.jpg"/>
        <img src="@/assets/creditInsurance/3.jpg"/>
        <img src="@/assets/creditInsurance/4.jpg"/>
      </div>
    </div>
    <footLink/>
  </div>
</template>

<script>
import titleBar from "@/components/common/titleBar.vue";
import footLink from "@/components/common/footLink.vue";
import titleFont from "@/components/common/titleFont.vue";
import advantageTitle from "@/components/common/advantageTitle.vue";

export default {
  components: {
    titleBar,
    footLink,
    titleFont,
    advantageTitle
  },
  data() {
    return {
      titleCn: '信保服务',
      titleEn: 'CREDIT INSURANCE SERVICE',
      image: require('@/assets/creditInsurance/1.jpg'),
      advantageTitle: [
        '小额投保全年免费',
        '一般投保费率最优',
        '在线投保简化流程',
        '信保专员优质服务'
      ]
    }
  },
}
</script>

<style lang="less" scoped>
#content {
  margin-top: 50px;
  margin-bottom: 50px;
}
.content {
  margin-top: 50px;
}
#content-title {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 2;           /* 行高 */
  text-align: center;           /* 对齐方式 */
  letter-spacing: 0.5px;      /* 字母间距 */
  word-spacing: 5px;          /* 单词间距 */
}
#content-imgs {
  margin-top: 40px;
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
img {
  margin-right: 30px;
  width: 25%;
}
.title1 {
  margin-left: 10%;
  font-size: 30px;
  font-weight: bold;
  background: linear-gradient(to right, rgba(98, 205, 196), rgba(33, 182, 234));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  margin-bottom: 12px;
  margin-top: 20px;
}
.function-content {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
  color: dimgrey;
  div {
    margin-right: 5%;
    margin-top: 1%;
    margin-bottom: 1%;
  }
}
#content-advantage {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  width: 80%;
  margin: auto;
}
.advantageTitle {
  margin-right: 3%;
  margin-bottom: 30px;
  margin-top: 40px;
}
</style>


