<template>
  <div>
    <titleBar :image=this.image :title-cn="this.titleCn" :title-en=this.titleEn />
    <titleFont title="加入我们"></titleFont>
    <div id="content">
      <div class="content">
        <el-card class="box-card">
          <div class="title">
            单证员
          </div>
          <div class="content-font">
            职位要求:
            <br/>
            1.专科以上学历(本科优先)，1年以上相关工作经验，英语基础好，具有熟练的英文口头及书面表达技巧，能熟练操作常用办公软件；
            <br/>
            2.熟悉外贸单证操作流程及相关知识(如做信用证、订舱、做清关资料等等)；
            <br/>
            3.具有较强的沟通协调能力，善于与运输、商检、海关、银行等有关部门紧密协作；
            <br/>
            4.工作认真仔细、严谨，有较强的工作热情和责任感；
          </div>
        </el-card>
      </div>
      <div class="content">
        <el-card class="box-card">
          <div class="title">
            外贸销售经理
          </div>
          <div class="content-font">
            岗位职责:
            <br/>
            1.熟悉掌握平台信息，了解客户的需求，掌握沟通技巧，正确解释并生动描述相关功能的特征与优点；
            <br/>
            2.准确、简洁、高效、友好的回复客户提出的各种问题；
            <br/>
            3.及时正确的做好备注工作，并能确保第一时间告知业务端，客户最新的进展；
            <br/>
            4.能及时跟进业务端转交的客户，并且协助企业完成注册备案走单等工作；
            <br/>
            5.熟悉平台操作流程等，能独立给客户讲解平台；
            <br/>
            6.完成领导交办的其他事宜；
            <br/>
            7.可以接受周末线上办公/轮休；
            <br/>
            <br/>
            任职资格:
            <br/>
            1.大专及以上学历，优秀者可适当放宽；
            <br/>
            2.1年以上售后工作经验；
            <br/>
            3.熟练使用world、excel、powerpoint等办公软件；
            <br/>
            4.必须有跨境贸易、货代，进出口税务，报关，跨境金融等相关经验；
          </div>
        </el-card>
      </div>
    </div>
    <footLink/>
  </div>
</template>

<script>
import titleBar from "@/components/common/titleBar.vue";
import footLink from "@/components/common/footLink.vue";
import titleFont from "@/components/common/titleFont.vue";

export default {
  components: {
    titleBar,
    footLink,
    titleFont
  },
  data() {
    return {
      titleCn: '加入我们',
      titleEn: 'JOIN US',
      image: require('@/assets/joinUs/1.jpg'),
    }
  },
}
</script>

<style lang="less" scoped>
#content {

  margin-top: 50px;
  margin-bottom: 50px;
}
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.box-card {
  width: 80%;
  box-shadow: 0 2px 12px 0 rgba(98, 205, 196);
  border-radius: 18px;
}
.title {
  font-size: 30px;
  margin-bottom: 10px;
  font-weight: bold;
  background: -webkit-linear-gradient(to right,  rgba(98, 205, 196), rgba(33, 182, 234)); /* Chrome, Safari */
  background: linear-gradient(to right, rgba(98, 205, 196), rgba(33, 182, 234)); /* 标准语法 */
  -webkit-background-clip: text; /* Chrome, Safari */
  -webkit-text-fill-color: transparent; /* Chrome, Safari */
  color: transparent; /* 兼容不支持background-clip的浏览器 */
}
.content-font {
  color: black;            /* 文字颜色 */
  font-size: 20px;            /* 字号 */
  line-height: 2;           /* 行高 */
  text-align: left;           /* 对齐方式 */
  letter-spacing: 0.5px;      /* 字母间距 */
  word-spacing: 5px;          /* 单词间距 */
}
</style>


