<template>
  <div id="ourServices">
    <div id="serves">
      <h2>我们的服务</h2>
      <span>OUR SERVICES</span>
      <div class="line"></div>
      <div id="bom">
          <div v-for="(item, index) in items" :key="index" class="serves">
            <Service :image="item.image" :title="item.title" :content="item.content"/>
          </div>
      </div>
  </div>
  </div>

</template>

<script>
import Service from './Service';
export default {
  components: {
    Service
  },
  data() {
    return {
      items: [
        {
          image: require('@/assets/icon/logistics.png'),
          title: '仓储物流',
          content: '与各大物流公司长期紧密合作，根据客户需求提供最优质、最便捷、最经济的物流服务，方便客户比价择优选择。',
        },
        {
          image: require('@/assets/icon/clearance.png'),
          title: '数智通关',
          content: '为进出口企业提供在线报关服务简化操作流程，实现申报、查询、自动归并、汇总数据等功能。利用信息化手段， 优化跨境电商进出口通关流程。',
        },
        {
          image: require('@/assets/icon/foreign.png'),
          title: '外汇服务',
          content: '根据平台交易数据，以贸易真实性为基础，为平台出口交易主体提供合规、阳光、便利的在线结汇服务。',
        },
        {
          image: require('@/assets/icon/finance.png'),
          title: '智能财税',
          content: '提供在线化税务服务，实现有票退税、无票免税、无票扣除、核定征收，支持企业阳光化和规范化发展。',
        },
        {
          image: require('@/assets/icon/CreditInsurance.png'),
          title: '信保服务',
          content: '由中国信保公司对您的交易对手做资信调查并提供交易担保， 为您的发货收款解决后顾之忧。',
        },
      ],
    }
  }
}
</script>

<style scoped>
#ourServices{
  width: 100%;
  text-align: center;
}
.serves {
  display: flex;
  margin-top: 30px;
  padding-left: 3vh;
  margin-left: 2px; /* 根据需要调整间距 */
}

#bom {
  display: flex;
  flex-wrap: wrap; /* 如果你希望它们能换行的话 */
  justify-content: center; /* 可选，用于水平居中对齐 */
}

.line {
  width: 4%;
  height: 3px;
  margin-left: 48%;
  margin-top: 1%;
  background-color: rgba(33, 182, 234);
}
</style>
