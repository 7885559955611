<template>
  <div class="icon">
    <div class="icon-img">
      <img class="icon-i" :src="this.imgPath">
      <div class="icon-title">{{this.title}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Advantage.vue',
  props: {
    imgPath: {
      type:String,
      default:''
    },
    title: {
      type:String,
      default:''
    }
  },
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.icon {
  display: flex;
  align-items: center;
  justify-items: center;
  text-align: center;
  width: 200px;
  height: 100px;
  box-shadow: 0 0 10px rgba(33, 182, 234); /* 阴影 */
}
.icon-img {
  text-align: center;
}
.icon-i {
  width: 20%;
}
.icon-title {
  width: 200px;
  margin-top: 8px;
  text-align: center;
}
</style>
