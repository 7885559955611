<template>
  <div>
    <titleBar :image=this.image :title-cn="this.titleCn" :title-en=this.titleEn />
    <div id="logistics">
      <div class="service">
        <div>
          <img class="img-left" src="@/assets/logistics/1.jpg">
        </div>
        <div class="content">
          <div class="title-logistics">服务介绍</div>
          <div class="content-font">
            与各大物流公司长期紧密合作，根据客户需求提供<span class="font-width">最优质，最便携，最经济</span>的物流服务，方便客户比价择优选择。
            <br/>
            <span class="font-width">专业</span>的物流供应协作，<span class="font-width">系统</span>的物流管理服务，有效降低物流成本。
            <br/>
            <span class="font-width">满足</span>客户海运、陆运、空运、小包快递等多种物流需求。
          </div>
        </div>
      </div>
      <img class="img-bottom" src="@/assets/logistics/2.jpg">
    </div>
    <process :arrows=this.arrows />
    <footLink/>
  </div>
</template>

<script>
import titleBar from "@/components/common/titleBar.vue";
import footLink from "@/components/common/footLink.vue";
import process from "@/components/common/process.vue";

export default {
  components: {
    process,
    titleBar,
    footLink
  },
  data() {
    return {
      titleCn: '物流服务',
      titleEn: 'LOGISTICS SERVICE',
      image: require('@/assets/logistics/3.jpg'),
      arrows: [
        {
          serial:'01',
          describe:'注册'
        },
        {
          serial:'02',
          describe:'下单'
        },
        {
          serial:'03',
          describe:'报关'
        },
        {
          serial:'04',
          describe:'收付款'
        },
        {
          serial:'05',
          describe:'出口退税'
        },
        {
          serial:'06',
          describe:'结算'
        }
      ]
    }
  },
}
</script>

<style lang="less" scoped>
#logistics{
  margin-top: 40px;
  width: 100%;
  display: grid;
  place-items: center;
}
.img-left {
  width: 600px;
  height: 400px;
}
.img-bottom {
  width: 80%;
  margin-bottom: 40px;
}
.service{
  width: 80%;
  height: 400px;
  display: flex;
  background-image: linear-gradient(to right, rgba(98, 205, 196), rgba(33, 182, 234));
}
.title-logistics {
  font-size: 25px;
  margin-top: 70px;
  margin-left: 40px;
  margin-bottom: 30px;
  font-weight: bold;
  color: white; /* 兼容不支持background-clip的浏览器 */
}
.content-font {
  margin-left: 40px;
  margin-right: 40px;
  color: white;            /* 文字颜色 */
  font-size: 18px;            /* 字号 */
  line-height: 2;           /* 行高 */
  text-align: left;           /* 对齐方式 */
  letter-spacing: 0.5px;      /* 字母间距 */
  word-spacing: 5px;          /* 单词间距 */
}
.font-width {
  font-weight: bold;
}
</style>


